
import React from "react";
import {createBrowserRouter} from "react-router-dom";


import pageLoader from "../layout/Loader.jsx";
import Layout     from "../layout/index.jsx";

import Home       from "../pages/Home/index.jsx";
import Events     from "../pages/Events/index.jsx";
import Error404   from "../pages/Error/404.jsx";
import Ebadge     from "../pages/Ebadge/index.jsx";
// import Contact    from "../pages/Contact/index.jsx";

const router = createBrowserRouter([
    { path: "/",            Component: Layout,  loader: pageLoader, errorElement: <Error404 />, children: [
        { index: true,      Component: Home,  loader: pageLoader,},
        { path: ":id",      Component: Ebadge,  loader: pageLoader,},
        { path: "*",        Component: Home, loader: pageLoader,},
    ]},
    { path: "/events",      Component: Layout,  loader: pageLoader, errorElement: <Error404 />, children: [
        { index: true,      Component: Events,  loader: pageLoader,},
        { path: "*",        Component: Events, loader: pageLoader,},
    ]},
]);

export default router;