import React from 'react'


import {
    CalendarIcon,
    ChartPieIcon,
    DocumentDuplicateIcon,
    FolderIcon,
    HomeIcon,
    UsersIcon,

} from '@heroicons/react/24/outline'

const projects = [
    { name: '订单统计', initials: '订单', bgColor: 'bg-pink-600',   stats: [{ name: '总订单', value: 300 }, { name: '已付款', value: 300 }, { name: '待确认', value: 300 }] },
    { name: '证件统计', initials: '证件', bgColor: 'bg-purple-600', stats: [{ name: '注册数', value: 300 }, { name: '已签到', value: 300 }, { name: '已制证', value: 300 }] },
    { name: '支付统计', initials: '支付', bgColor: 'bg-yellow-500', stats: [{ name: '总收款', value: 300 }, { name: '微信', value: 300 }, { name: '支付宝', value: 300 }, { name: '转账', value: 300 }] },
]

const navigation = [
    { name: '数据面板', href: '#', icon: HomeIcon, current: true },
    { name: '报名管理', href: '#', icon: UsersIcon, current: false },
    { name: '证件管理', href: '#', icon: FolderIcon, current: false },
    { name: '支付管理', href: '#', icon: CalendarIcon, current: false },
    { name: '数据统计', href: '#', icon: DocumentDuplicateIcon, current: false },
    { name: '系统管理', href: '#', icon: ChartPieIcon, current: false },
    { name: '帮助文档', href: '#', icon: HomeIcon, current: false },
    { name: '联系我们', href: '#', icon: UsersIcon, current: false },
    { name: '关于我们', href: '#', icon: FolderIcon, current: false },
    { name: '联系我们', href: '#', icon: UsersIcon, current: false },
    { name: '关于我们', href: '#', icon: FolderIcon, current: false },
    { name: '数据统计', href: '#', icon: DocumentDuplicateIcon, current: false },
    { name: '系统管理', href: '#', icon: ChartPieIcon, current: false },
    { name: '帮助文档', href: '#', icon: HomeIcon, current: false },
    { name: '联系我们', href: '#', icon: UsersIcon, current: false },
    { name: '关于我们', href: '#', icon: FolderIcon, current: false },
    { name: '联系我们', href: '#', icon: UsersIcon, current: false },
    { name: '关于我们', href: '#', icon: FolderIcon, current: false },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Event() {
    return (
        <div className="w-full h-full flex flex-col bg-[#04b4e0]">
            <div class="w-full flex flex-col">
                <div class="relative min-h-[150px] max-h-[150px] overflow-hidden">
                    <img src="/images/event.jpg" alt="会议/展览/活动 票证解决方案"
                        class="absolute inset-0 w-full h-full object-cover transition-transform transform duration-500 scale-100 hover:scale-150 aspect-ratio-16/9" />
                </div>
                <div class="flex flex-col justify-between items-center p-2 gap-2 text-md text-white">
                    <h1 class="text-lg font-medium text-pretty text-center">会议/展览/活动 票证解决方案</h1>
                    <div class="w-full flex flex-row justify-evenly items-center text-md">
                        <div class="flex flex-col justify-center items-center">
                            <p class="text-xs font-medium">2024年07月27日</p>
                            <p class="font-semibold uppercase">19:00</p>
                        </div>
                        <div class="flex flex-col justify-center items-center">
                            <p class="text-xs font-medium">2024年07月27日</p>
                            <p class="font-semibold uppercase">19:00</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="p-1">
                <ul className="grid grid-cols-1 gap-1 ">
                    {projects.map((project) => (
                        <li key={project.name} className="col-span-1 flex shadow-sm text-white">
                            <div
                                className={classNames(
                                    project.bgColor,
                                    'flex w-12 flex-shrink-0 items-center justify-center text-sm font-medium text-white border-l border-t border-b border-gray-200',
                                )}
                            >
                                {project.initials}
                            </div>
                            <div className="flex flex-1 items-center justify-around truncate  border border-gray-200 -bg-white">
                                {project.stats.map((stat) => (
                                    <div class="flex flex-1 flex-col justify-center items-center p-1">
                                        <h3 class="text-sm font-semibold uppercase">{stat.value}</h3>
                                        <p class="text-xs text-gray-100">{stat.name}</p>
                                    </div>
                                ))}
                            </div>
                        </li>
                    ))}
                </ul>
            </div>

            <div className='flex-1 w-full p-1'>
                <nav className="p-1 w-full">
                    <ul className="grid grid-cols-4 gap-1">
                        {navigation.map((item) => (
                        <li key={item.name} className="aspect-square">
                            <a
                                href={item.href}
                                className='relative w-full h-full group gap-y-1 hover:gap-y-2 flex flex-col justify-center items-center text-gray-800'
                            >
                                <div className="absolute inset-0 w-full h-full bg-white opacity-20 group-hover:animate-ping"></div>
                                <item.icon aria-hidden="true" className="h-6 w-6 shrink-0 transition-transform transform duration-500 scale-100 group-hover:scale-110 group-hover:text-white" />
                                <span className="text-xs font-normal transition-transform transform duration-500  scale-100 group-hover:scale-110 group-hover:text-white">{item.name}</span>
                            </a>
                        </li>
                        ))}
                    </ul>
                </nav>
            </div>

            <div className="hidden x-flex flex-col justify-center items-center space-y-2 p-1 text-xs text-gray-100 bg-[rgb(4,180,224)]">
                <span className="isolate inline-flex rounded-md shadow-sm mt-4 text-sm font-semibold text-gray-900 ">
                    <button type="button" className="relative inline-flex items-center rounded-l-md bg-white px-3 py-2 ring-1 ring-inset ring-gray-300 hover:bg-gray-200 focus:z-10">简体中文</button>
                    <button type="button" className="relative -ml-px inline-flex items-center bg-white px-3 py-2 ring-1 ring-inset ring-gray-300 hover:bg-gray-200 focus:z-10">English</button>
                    <button type="button" className="relative -ml-px inline-flex items-center rounded-r-md bg-white px-3 py-2 ring-1 ring-inset ring-gray-300 hover:bg-gray-200 focus:z-10">Español</button>
                </span>
                <p> © 2024 All rights reserved.</p>
                <a href="//beian.miit.gov.cn/" target="_blank" rel="noopener noreferrer">京ICP备14042301号-3</a>
            </div>
        </div>
    )
}
