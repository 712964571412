// const environment = process.env.NODE_ENV || 'development';

const CA =`\
-----BEGIN CERTIFICATE-----
QhqpDgefoya2SU+BKtE+eQU2FsdGVkX198OlY2MIwGPc4+tSSy82451pcI9bpkC4A7YxbpCeYTxMckk0P/HozA4QO8eKCxRu6HPsLtBCvGUalcbUZ0YrmHLwKLEq6DGO+p7FjYY0/xn0huZvNek4EBKM+NoZ/zWZlo7v+Q31xtF72LvAeLMjHfuh+nd+WWdLY=
aNxmjE76dd31ibMh3O+2YAU2FsdGVkX19YNNI+9zuxLt/UchsqRYXGtB7j4RvH+QLdcT2aGkQw5YLYtXstSNlpC3c5lrxmS/srJRbvwSbC6kBfQSYlzY0HOiLAjqfO9Cxh1c6/efMwmhs4LAGoLnsiKVHuz05XzkG9TxSjAQw4ojrpx1icgho9hcOfA43Ajl4=
UR0z/ahqK1WEE9tevJDenAU2FsdGVkX199euLYSrFmDHIyibURoIaTqAeWrbpuc21ggBio4B7zeptV0FYTgLGxZapD+tJZxCaM+stePfoAI/+drFVCDpVLH6nOdFxs8hLDkP6XCR8y+Ey+Z3k+wwizZ94WkHvXLyd+Ofua/uL909RwKiiKFHP3urE8ud0oHsc=
-----END CERTIFICATE-----
`;


const Settings = {
    simulation: false,
    tk: 'cotoken',
    ah: 'https://devapi.ebadge.cn/v1', //for portal  rw
    ak: ['please', 'contact', 'joe#wzhu.net'].join('-'),
    as: ['for', 'a', 'license'].join('-'),
    ds: ['all', 'rights', 'reserved'].join('-'),
}

const AMapSettings = {
    key: "8210f1c05ea676120e284a84c1106a1c", // 申请好的Web端开发者Key，首次调用 load 时必填
    version: "2.0",                          // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
    token: '_AMap_Token',
    // plugins: ['AMap.AutoComplete'],
    // plugins:['AMap.ToolBar','AMap.Scale', 'AMap.PlaceSearch', 'AMap.AutoComplete', 'AMap.Geocoder'],  // 需要使用的的插件列表，如比例尺'AMap.Scale'等
};

const Enums = {
    'user_types'         :[{value:0, label:"请刷新以重新加载"}], //用户类型
    'auth_types'         :[{value:0, label:"请刷新以重新加载"}], //账户类型
    "event_types"        :[{value:0, label:"请刷新以重新加载"}], //活动类型
    "ticket_types"       :[{value:0, label:"请刷新以重新加载"}], //票务类型
    "institution_types"  :[{value:0, label:"请刷新以重新加载"}], //机构类型
    "institution_levels" :[{value:0, label:"请刷新以重新加载"}], //机构级别
    'collaborator_levels':[{value:0, label:"请刷新以重新加载"}], //合作伙伴级别
    "folder_tags"        :[{value:0, label:"请刷新以重新加载"}], //文件夹标签
    "entry_statuses"     :[{value:0, label:"请刷新以重新加载"}], //作品状态
    "entry_levels"       :[{value:0, label:"请刷新以重新加载"}], //作品级别
    "entry_groups"       :[{value:0, label:"请刷新以重新加载"}], //作品赛道
}

const Categories = {
    'entry' :  [{value:0, label:"请刷新以重新加载"}],
};



export { Settings, AMapSettings, CA, Enums, Categories };
