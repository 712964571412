import React from 'react';
import { useParams } from 'react-router-dom';
import QRCode from 'qrcode.react';
import { getEbadge } from '../../api/ebadge';

const Image = ({ src, alt, className,  bakSrc }) => {
  const [imgSrc, setImgSrc] = React.useState(src);

  const handleError = () => {
    console.error(`Image (${alt}) ${src} load error`);
    setImgSrc(bakSrc);
  };

  React.useEffect(() => {
    setImgSrc(src);
  }, [src]);

  return <img src={imgSrc} alt={alt} className={className} onError={handleError} />;
};


export default function Ebadge() {

  const { id } = useParams();

  const [ebadge, setEbadge] = React.useState({

    event: '请稍等片刻, 正在加载中...',
    coverurl: '/images/event.jpg',
    startymd: '----年--月-日',
    starthms: '--:--AM',
    closeymd: '----年--月-日',
    closehms: '--:--AM',
    address: 'xxx市xxx区xxx路xxx大厦xxx层xxxx厅',

    ticket: '票种名称',
    name: '姓 名',
    avatarurl: '/images/avatar.png',
    title: '职 位',
    company: '公司/单位名称',
    stamp: '加载中',
    scan: '请于现场出示此二维码',
    qrlogo: '/images/logo.png',
    qrcode: 'https://ebadge.cn/',
  });



  React.useEffect(() => {
    // if (!id || id.length !== 6) {
    //   return;
    // }
    getEbadge(id).then((res) => {
      console.log("res", res);
      setEbadge(res);
    })

    // setEbadge();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);


  return (
    <>

      <div className="flex flex-col rounded-t-2xl">
        {/* 活动Cover */}
      {ebadge.coverurl?.length > 0 &&
        <div className="relative min-h-[150px] max-h-[200px] overflow-hidden rounded-t-2xl -hidden">
          <Image
            src={ebadge.coverurl}
            className="absolute inset-0 w-full object-cover rounded-t-2xl transition-transform transform duration-500 scale-100 hover:scale-150 aspect-ratio-16/9"
            alt={ebadge.event}
            bakSrc={'/images/event.jpg'}
          />
        </div>
      }
        {/* 活动信息 */}
        <div className="flex flex-col justify-between items-center p-4 gap-2 text-md rounded-t-2xl">
          <h1 className="text-2xl font-medium text-pretty text-center">{ebadge.event}</h1>
          <div className="w-full flex flex-row justify-between items-center text-md">
            <div className="flex flex-col justify-center items-center">
              <p className="text-xs font-medium text-gray-700">
                {ebadge.startymd}
              </p>
              <p className="font-semibold uppercase">{ebadge.starthms}</p>
            </div>
            <div className="flex flex-col">
              <p className="text-xs font-light w-full">起止时间</p>
            </div>
            <div className="flex flex-col justify-center items-center">
              <p className="text-xs font-medium text-gray-700">{ebadge.closeymd}</p>
              <p className="font-semibold uppercase">{ebadge.closehms}</p>
            </div>
          </div>
          {ebadge.address?.length> 0 &&
          <div className="w-full flex flex-row justify-between items-start text-sm gap-2">
            <p className="font-medium text-gray-700">地址:</p>
            <p className="flex-1 text-gray-900 text-pretty">{ebadge.address}</p>
          </div>
          }
        </div>
      </div>

      {/* 票券信息 */}
      <div className="flex flex-row justify-betweenitems-center
                    p-4 text-sm relative gap-4
                    border-t-2 border-dashed border-gray-400
                    before:bg-gray-400 before:w-5 before:h-5 before:absolute before:-top-3 before:-left-2 before:rounded-full
                    after:bg-gray-400 after:w-5 after:h-5 after:absolute after:-top-3 after:-right-2 after:rounded-full ">
        {ebadge.avatarurl?.length > 0 &&
        <Image
          src={ebadge.avatarurl}
          className="rounded-md max-w-24 max-h-24 aspect-square"
          alt={ebadge.name}
          bakSrc={'/images/avatar.png'}
         />
        }
        <div className="flex flex-col justify-between items-center flex-1" >

          {ebadge.ticket?.length > 0 && <p className="uppercase text-gray-700 text-pretty text-center">{ebadge.ticket}</p>}
          {ebadge.name?.length> 0    && <p className="uppercase text-xl text-black font-semibold text-pretty text-center">{ebadge.name}</p>}
          {ebadge.title?.length> 0   && <p className="uppercase text-gray-700 text-pretty text-center">{ebadge.title}</p>}
          {ebadge.company?.length> 0 && <p className="uppercase text-gray-700 text-pretty tex-center">{ebadge.company}</p>}

          {ebadge.stamp?.length > 0 &&
            <span className="stamp absolute z-50 -rotate-12 bottom-0 right-0
                transform -translate-x-1/2
                text-red-500 text-3xl font-semibold
                py-1 px-4 rounded-sm border-4 border-solid border-red-500
                after:content-[''] after:absolute after:inset-0 after:w-full after:h-full after:scale-125
                ">{ebadge.stamp}</span>
          }
        </div>
      </div>

      {/* 二维码 */}
      <div className="flex flex-col justify-between items-center
                    p-4 pb-1 text-sm relative
                    border-t-2 border-dashed border-gray-400
                    before:bg-gray-400 before:w-5 before:h-5 before:absolute before:-top-3 before:-left-2 before:rounded-full
                    after:bg-gray-400 after:w-5 after:h-5 after:absolute after:-top-3 after:-right-2 after:rounded-full ">
        <QRCode value={ebadge.qrcode}
          imageSettings={{ src: ebadge.qrlogo, height: 20, width: 20, excavate: true }} />

        <p className="text-xs font-light text-gray-700">{ebadge.scan}</p>
      </div>
    </>
  )
}
