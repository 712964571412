import React from 'react'
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react'
import { ChevronDownIcon, PhoneIcon, PlayCircleIcon, RectangleGroupIcon } from '@heroicons/react/20/solid'
import { Bars3Icon, XMarkIcon, ChartPieIcon, CursorArrowRaysIcon, FingerPrintIcon, SquaresPlusIcon } from '@heroicons/react/24/outline'

import { Dialog, DialogBackdrop, DialogPanel, TransitionChild } from '@headlessui/react'

import {
  CalendarIcon,
  DocumentDuplicateIcon,
  FolderIcon,
  HomeIcon,
  UsersIcon,
} from '@heroicons/react/24/outline'

import Aside from '../../components/Aside/Event';
const navigation = [
  { name: '数据面板', href: '#', icon: HomeIcon, current: true },
  { name: '报名管理', href: '#', icon: UsersIcon, current: false },
  { name: '证件管理', href: '#', icon: FolderIcon, current: false },
  { name: '支付管理', href: '#', icon: CalendarIcon, current: false },
  { name: '数据统计', href: '#', icon: DocumentDuplicateIcon, current: false },
  { name: '系统管理', href: '#', icon: ChartPieIcon, current: false },
  { name: '帮助文档', href: '#', icon: HomeIcon, current: false },
  { name: '联系我们', href: '#', icon: UsersIcon, current: false },
  { name: '关于我们', href: '#', icon: FolderIcon, current: false },
]

const solutions = [
  {
    name: 'Analytics',
    description: 'Get a better understanding of where your traffic is coming from',
    href: '#',
    icon: ChartPieIcon,
  },
  {
    name: 'Engagement',
    description: 'Speak directly to your customers with our engagement tool',
    href: '#',
    icon: CursorArrowRaysIcon,
  },
  { name: 'Security', description: "Your customers' data will be safe and secure", href: '#', icon: FingerPrintIcon },
  {
    name: 'Integrations',
    description: "Connect with third-party tools that you're already using",
    href: '#',
    icon: SquaresPlusIcon,
  },
]
const callsToAction = [
  { name: 'Watch demo', href: '#', icon: PlayCircleIcon },
  { name: 'Contact sales', href: '#', icon: PhoneIcon },
  { name: 'View all products', href: '#', icon: RectangleGroupIcon },
]


// function classNames(...classes) {
//   return classes.filter(Boolean).join(' ')
// }


export default function Events() {
  const [sidebarOpen, setSidebarOpen] = React.useState(false)
  return (
    <div className='w-full h-full flex flex-row'>
      {/* mobile dialog sidebar */}
      <Dialog open={sidebarOpen} onClose={setSidebarOpen} className="relative z-50 lg:hidden">
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-gray-900/80 transition-opacity duration-300 ease-linear data-[closed]:opacity-0"
        />
        <div className="fixed inset-0 flex">
          <DialogPanel
            transition
            className="relative mr-16 flex w-full max-w-xs flex-1 transform transition duration-300 ease-in-out data-[closed]:-translate-x-full"
          >
            <TransitionChild>
              <div className="absolute left-full top-0 flex w-16 justify-center pt-5 duration-300 ease-in-out data-[closed]:opacity-0">
                <button type="button" onClick={() => setSidebarOpen(false)} className="-m-2.5 p-2.5">
                  <span className="sr-only">Close sidebar</span>
                  <XMarkIcon aria-hidden="true" className="h-6 w-6 text-white" />
                </button>
              </div>
            </TransitionChild>

            <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 pb-2 ring-1 ring-white/10">
              <Aside />
            </div>
          </DialogPanel>
        </div>
      </Dialog>

      {/* Secondary Aside */}
      <aside className="hidden lg:flex lg:flex-col w-80 h-full overflow-y-auto border-r border-gray-200">
        <Aside />
      </aside>

      <div className="w-full h-full flex-1 flex flex-col">
        <Popover className="w-full relative z-50 isolate shadow">
          <div className="bg-white py-5">
            <div className="mx-auto max-w-7xl px-6 lg:px-8 flex items-center justify-between">
              <button type="button" onClick={() => setSidebarOpen(true)} className="p-2 text-gray-400 lg:hidden">
                <span className="sr-only">Open sidebar</span>
                <Bars3Icon aria-hidden="true" className="h-6 w-6" />
              </button>
              <div className="font-semibold">报名/证件列表</div>
              <PopoverButton className="inline-flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900">
                Solutions
                <ChevronDownIcon aria-hidden="true" className="h-5 w-5" />
              </PopoverButton>
            </div>
          </div>

          <PopoverPanel
            transition
            className="absolute inset-x-0 top-0 -z-10 bg-white pt-16 shadow-lg ring-1 ring-gray-900/5 transition data-[closed]:-translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in"
          >
            <div className="mx-auto grid max-w-7xl grid-cols-1 gap-2 px-6 py-6 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-0 sm:py-10 lg:grid-cols-4 lg:gap-4 lg:px-8 xl:gap-8">
              {solutions.map((item) => (
                <div
                  key={item.name}
                  className="group relative -mx-3 flex gap-6 rounded-lg p-3 text-sm leading-6 hover:bg-gray-50 sm:flex-col sm:p-6"
                >
                  <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                    <item.icon aria-hidden="true" className="h-6 w-6 text-gray-600 group-hover:text-indigo-600" />
                  </div>
                  <div>
                    <a href={item.href} className="font-semibold text-gray-900">
                      {item.name}
                      <span className="absolute inset-0" />
                    </a>
                    <p className="mt-1 text-gray-600">{item.description}</p>
                  </div>
                </div>
              ))}
            </div>
            <div className="bg-gray-50">
              <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
                <div className="grid grid-cols-1 divide-y divide-gray-900/5 sm:grid-cols-3 sm:divide-x sm:divide-y-0 sm:border-x sm:border-gray-900/5">
                  {callsToAction.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="flex items-center gap-x-2.5 p-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-100 sm:justify-center sm:px-0"
                    >
                      <item.icon aria-hidden="true" className="h-5 w-5 flex-none text-gray-400" />
                      {item.name}
                    </a>
                  ))}
                </div>
              </div>
            </div>
          </PopoverPanel>
        </Popover>

        <div className="w-full h-full flex-1 overflow-y-auto p-5 space-y-5  text-center">

          <h3 className="text-xl font-medium text-gray-800">模板1</h3>
          <div className="mx-auto max-w-md h-screen flex flex-col items-center bg-[#04b4e0]">

            <div class="w-full flex flex-col">
              <div class="relative h-40 overflow-hidden">
                <img src="/images/event.jpg" alt="会议/展览/活动 票证解决方案"
                  class="absolute inset-0 w-full h-full object-cover transition-transform transform duration-500 scale-100 hover:scale-125" />
              </div>
              <div class="flex flex-col justify-between items-center p-2 gap-2 text-md text-white">
                <h1 class="text-xl font-medium text-pretty text-center">会议/展览/活动 票证解决方案</h1>
                <div class="w-full flex flex-row justify-evenly items-center text-md">
                  <div class="flex flex-col justify-center items-center">
                    <p class="text-xs font-medium">2024年07月27日</p>
                    <p class="font-semibold uppercase">19:00</p>
                  </div>
                  <div class="flex flex-col justify-center items-center">
                    <p class="text-xs font-medium">2024年07月27日</p>
                    <p class="font-semibold uppercase">19:00</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="w-full flex-1 p-5 pt-2 flex flex-col justify-center items-center">
              <ul className="w-full grid grid-cols-3 gap-3">
                {navigation.map((item) => (
                  <li key={item.name} className="aspect-square">
                    <a
                      href={item.href}
                      className='relative w-full h-full group gap-y-1 hover:gap-y-2 x-rounded-xl flex flex-col justify-center items-center text-gray-800'
                    >
                      <div className="absolute inset-0 w-full h-full bg-white opacity-20 x-rounded-xl group-hover:animate-ping"></div>
                      <item.icon aria-hidden="true" className="h-10 w-10 shrink-0 transition-transform transform duration-500 scale-100 group-hover:scale-110 group-hover:text-white" />
                      <span className="text-md font-normal transition-transform transform duration-500  scale-100 group-hover:scale-110 group-hover:text-white">{item.name}</span>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <div className="w-full flex flex-col justify-center items-center space-y-1 p-2 text-xs text-gray-100">
              <p> © {new Date().getFullYear()} All rights reserved.</p>
              <p> 即刻会议 技术支持</p>
              <a href="//beian.miit.gov.cn/" target="_blank" rel="noopener noreferrer">京ICP备14042301号-3</a>
            </div>
          </div>



          <h3 className="text-xl font-medium text-gray-800">模板2</h3>
          <div className="mx-auto max-w-md h-screen flex flex-col items-center bg-[#04b4e0]">
            <div class="w-full flex flex-col">
              <div class="relative h-40 overflow-hidden">
                <img src="/images/event.jpg" alt="会议/展览/活动 票证解决方案"
                  class="absolute inset-0 w-full h-full object-cover
                transition-transform transform duration-500 scale-100 hover:scale-125
                " />
              </div>
            </div>
            <div class="flex-1 w-full flex flex-col justify-center items-center">
              <div className="w-full grid grid-cols-3 gap-4 p-4 ">
                {navigation.map((item) => (
                  <div key={item.name} className="aspect-square flex flex-col items-center justify-center p-4 rounded-lg shadow-md bg-white bg-opacity-50 transform hover:scale-105 transition-transform duration-500 ease-in-out">
                    <item.icon className="h-6 w-6 mb-2" aria-hidden="true" />
                    <span className="text-sm font-medium text-gray-700">{item.name}</span>
                  </div>
                ))}
              </div>
            </div>
            <div className="w-full flex flex-col justify-center items-center space-y-1 p-2 text-xs text-gray-100">
              <p> © {new Date().getFullYear()} All rights reserved.</p>
              <p> 即刻会议 技术支持</p>
              <a href="//beian.miit.gov.cn/" target="_blank" rel="noopener noreferrer">京ICP备14042301号-3</a>
            </div>
          </div>




          <h3 className="text-xl font-medium text-gray-800">模板3</h3>
          <div className="mx-auto max-w-md h-screen flex flex-col items-center bg-[#04b4e0]">
            <div class="w-full flex flex-col">
              <div class="relative h-40 overflow-hidden">
                <img src="/images/event.jpg" alt="会议/展览/活动 票证解决方案"
                  class="absolute inset-0 w-full h-full object-cover
                transition-transform transform duration-500 scale-100 hover:scale-125
                " />
              </div>
            </div>
            <div class="flex-1 w-full flex flex-col justify-center items-center">
              <div className="w-full grid grid-cols-3 gap-4 p-4 ">
              {navigation.map((item) => (
                <div key={item.name} className="aspect-square flex flex-col items-center justify-center p-4 rounded-full shadow-md bg-white bg-opacity-50 transform hover:scale-105 transition-transform duration-500 ease-in-out">
                  <item.icon className="h-6 w-6 mb-2" aria-hidden="true" />
                  <span className="text-sm font-medium text-gray-700">{item.name}</span>
                </div>
              ))}
              </div>
            </div>
            <div className="w-full flex flex-col justify-center items-center space-y-1 p-2 text-xs text-gray-100">
              <p> © {new Date().getFullYear()} All rights reserved.</p>
              <p> 即刻会议 技术支持</p>
              <a href="//beian.miit.gov.cn/" target="_blank" rel="noopener noreferrer">京ICP备14042301号-3</a>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}
