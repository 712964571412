import React from 'react'

export default function Profile() {

    const portfolio = [
        { name: '从业年限', value: 20 },
        { name: '项目案例', value: 60 },
        { name: '单日PV', value: 80 },
    ];

    const profile = {
        name: '朱伟伟',
        title: 'Web全栈工程师',
        address: '蓝色星球🌍',
        email: 'joe＠wzhu.net',
        phone: '13800138000',
        wechat: 'zhuweiwei',
        qq: '1234567890',
        weibo: 'zhuweiwei',
        github: 'zhuweiwei',
        avatar: '/images/zhuweiwei.jpg',
    }

    return (
        <div className="w-full h-full overflow-hidden bg-[#04b4e0]">
            <div className="w-full h-full flex flex-col items-center justify-start text-white">
                <img src="/images/banner.jpg" className="h-24 lg:h-48 w-full object-cover" alt="Wallpaper" />
                <div className="w-full flex justify-center relative">
                    <img src={profile.avatar} className="w-36 h-36 object-cover rounded-full border-4 border-white absolute -top-20" alt={profile.name} />
                </div>
                <div className="w-full flex flex-col justify-center items-center text-center mt-20 mb-4">
                    <h1 className="text-3xl font-semibold mb-2">{profile.name}</h1>
                    <p className="text-lg font-light whitespace-pre-line">{profile.title}</p>
                    <p className="text-lg font-light whitespace-pre-line hidden lg:block">{profile.description}</p>
                </div>
                <div className="w-full -flex justify-evenly mb-4 hidden lg:flex">
                    {portfolio.map((item, index) => (
                        <div className="flex flex-col-reverse justify-center items-center" key={index}>
                            <h3 className="text-sm">{item.name}</h3>
                            <p className="text-2xl">{item.value}</p>
                        </div>
                    ))}
                </div>
                <div className="pl-4 py-1 -flex flex-col space-y-2 justify-start items-start text-sm hidden lg:flex">
                    <p className="font-normal">地址 <span className="font-light">{profile.address}</span>
                    </p>
                    <p className="font-normal">职称 <span className="font-light">{profile.title}</span>
                    </p>
                    <p className="font-normal">邮箱 <span className="font-light select-none">{profile.email}</span>
                    </p>
                </div>
                <div className="absolute bottom-0 left-0 right-0 flex flex-col justify-center items-center space-y-2 p-1 text-xs text-gray-100 bg-[rgb(4,180,224)]">
                    <span className="isolate inline-flex rounded-md shadow-sm mt-4 text-sm font-semibold text-gray-900 ">
                        <button type="button" className="relative inline-flex items-center rounded-l-md bg-white px-3 py-2 ring-1 ring-inset ring-gray-300 hover:bg-gray-200 focus:z-10">简体中文</button>
                        <button type="button" className="relative -ml-px inline-flex items-center bg-white px-3 py-2 ring-1 ring-inset ring-gray-300 hover:bg-gray-200 focus:z-10">English</button>
                        <button type="button" className="relative -ml-px inline-flex items-center rounded-r-md bg-white px-3 py-2 ring-1 ring-inset ring-gray-300 hover:bg-gray-200 focus:z-10">Español</button>
                    </span>
                    <p> © 2024 All rights reserved.</p>
                    <a href="//beian.miit.gov.cn/" target="_blank" rel="noopener noreferrer">京ICP备14042301号-3</a>
                </div>
            </div>
        </div>
    )
}
